<template>
  <div>
    <el-table
      :data="data">
      <el-table-column
        prop="id"
        type="index"
        :index="indexMethod"
        label="序号">
      </el-table-column>
      <el-table-column
        prop="updatedAt"
        label="提现时间">
      </el-table-column>
      <el-table-column
        prop="amount"
        label="提现金额">
      </el-table-column>
      <el-table-column
        prop="cardNo"
        label="发放卡号">
      </el-table-column>
      <el-table-column
        prop="username"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态">
        <template slot-scope="scope">
          {{
            {
              0: '待处理',
              1: '已完成',
              2: '已取消'
            }[scope.row.status]
          }}
        </template>
      </el-table-column>
    </el-table>
    <div class="task-pages">
      <pagination
        @paginationChange="paginationChange"
        :pageInfo="pageInfo">
      </pagination>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
import Pagination from '@/components/pagination/index.vue'
export default {
  components: {
    Pagination
  },
  data () {
    return {
      totalNum: 500,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 5,
        total: 10
      }
    }
  },
  created () {
    this.withdrawalList()
  },
  methods: {
    paginationChange (val) {
      this.pageInfo.pageNum = val
      this.withdrawalList()
    },
    indexMethod (index) {
      // 当前页数 - 1 * 每页数据条数 + index + 1
      return (this.pageInfo.pageNum - 1) * this.pageInfo.pageSize + index + 1
    },
    // 用户提现记录列表
    withdrawalList () {
      api.withdrawalListTable({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.list
          this.pageInfo.total = res.data.data.total
          this.pageInfo.pageNum = res.data.data.currentPage
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .task-pages {
    margin-top: 20px;
    text-align: center;
}
</style>
