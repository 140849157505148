<template>
  <el-dialog
    title="绑定银行卡"
    :visible.sync="$_visible"
    width="500px"
    class="login_dialog">
    <el-form
      :model="PersonForm"
      ref="PersonForm">
      <el-form-item
        label="姓名"
        prop="name">
        <el-input
          type="text"
          size="small"
          style="width:320px;margin-left:40px;"
          v-model.trim="PersonForm.name">
        </el-input>
      </el-form-item>
      <el-form-item
        label="卡号"
        prop="cardId">
        <el-input
          type="text"
          size="small"
          maxlength="19"
          style="width:320px;margin-left:40px;"
          v-model.trim="PersonForm.cardId">
        </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
        <el-button
          @click="closeDialog"
          size="small">
          取消
        </el-button>
        <el-button
          @click="bindBankCard"
          type="primary"
          size="small">
          确定
        </el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      PersonForm: {
        name: '', // 姓名
        cardId: '' // 卡号
      },
      btnLoading: false
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    }
  },
  created () {
    const res = JSON.parse(localStorage.getItem('userInfo'))
    this.PersonForm.name = res.name
    this.PersonForm.cardId = res.cardNo
  },
  methods: {
    closeDialog () {
      this.$_visible = false
    },
    bindBankCard () {
      this.$refs.PersonForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.updateUserInfo({
            username: this.PersonForm.name,
            cardNo: this.PersonForm.cardId
          }).then(res => {
            this.btnLoading = false
            if (res.data.code === 0) {
              this.$message.success('绑定成功')
              this.closeDialog()
              this.$store.dispatch('getUserInfo')
            } else {
              this.$message.error(res.data.message)
            }
          }).catch((error) => {
            this.btnLoading = false
            console.log(error)
            this.$message.error('绑定失败')
          })
        } else {
          console.log('error submit')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login_dialog {
  .el-dialog {
    .el-dialog__header {
      padding: 19px 0px 0px 20px;
    }
    .el-dialog__body {
      padding: 26px 20px 30px 20px;
    }
    .el-dialog__footer {
      padding: 0px 20px 20px 0px;
    }
  }
}
</style>
