<template>
  <div class="task_content">
    <div class="task_content_top">
      <div class="task_content-left">
        <div class="task-content-logo">
          <img src="@/assets/images/person-money.png" alt="">
        </div>
        <div class="person">
          <div class="person-detail">
            <div class="person-title">
              <span>个人账号余额</span>
            </div>
            <div class="person_withdrawal">
              <span class="font_style_two">{{userInfo.amount}}</span>
              <span class="font_style_three">元</span>
            </div>
            <div class="font_button">
              <el-button
                type="primary"
                size="small"
                style="width:80px"
                @click="judge">
                提现
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <el-button
          size="mini"
          class="withdrawal_set"
          @click="withdrawal">
          提现设置
        </el-button>
      </div>
    </div>
      <div class="task-table">
        <el-tabs
          v-model="activeName"
          style="font-weight:bold">
          <el-tab-pane
            label="到账记录"
            name="first">
            <arrival-record></arrival-record>
          </el-tab-pane>
          <el-tab-pane
            label="提现记录"
            name="second">
            <withdrawal-record></withdrawal-record>
          </el-tab-pane>
        </el-tabs>
    </div>
    <person-withdrawal-dialog
      :visible.sync="dialogVisible">
    </person-withdrawal-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import PersonWithdrawalDialog from './components/person-withdrawal-dialog.vue'
import ArrivalRecord from './components/arrival-record.vue'
import WithdrawalRecord from './components/withdrawal-record.vue'
export default {
  components: {
    PersonWithdrawalDialog,
    ArrivalRecord,
    WithdrawalRecord
  },
  data () {
    return {
      // 子组件所需值
      dialogVisible: false,
      totalNum: 500,
      activeName: 'first'
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo
    }
  },
  methods: {
    withdrawal () {
      this.dialogVisible = true
    },
    judge () {
      if (this.userInfo.cardNo === null) {
        this.open()
      } else {
        this.withdrawMoney()
      }
    },
    open () {
      this.$confirm('暂未绑定银行卡，请在个人中心右上角绑定后提现', '提现', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '请绑定银行卡'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    withdrawMoney () {
      this.$confirm('每月只能提现一次，请确认您的操作', '提现', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '成功，请等待管理员审核'
        })
        this.userWithdrawal()
      })
    },
    styleClick (value) {
      this.tab = value
    },
    // 用户提现
    userWithdrawal () {
      api.withdrawalApplication({
        amount: String(this.amountMoney)
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('成功')
        } else {
          this.$message.error('每月仅可提现一次')
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求异常')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.task_content {
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: column;
  overflow: auto;
  .task_content_top {
    width: 100%;
    height: 240px;
    position: relative;
    background: #FFFFFF;
    background-image: url('../../../../assets/images/task-right-logo.png');
    background-repeat: no-repeat;
    background-position: right bottom;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    justify-content:space-between;
    overflow: auto;
    .task_content-left {
      display: flex;
      justify-content: flex-start;
      .task-content-logo {
        padding:13px 30px 12px 40px;
      }
      .person {
        .person-detail {
          padding-top: 46px;
          .person_withdrawal {
            margin-top:20px;
            .font_style_two {
              width: 80px;
              height: 42px;
              font-size: 30px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #5DD5C8;
            }
            .font_style_three {
              width: 14px;
              height: 20px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: bold;
              line-height: 0px;
              color: #5DD5C8;
              margin-left: 5px;
            }
          }
          .font_button {
            margin-top: 34px;
          }
          .person-title {
            width: 240px;
            height: 28px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #303133;
          }
        }
      }
    }
    .withdrawal_set {
      margin-top: 20px;
      margin-right:20px;
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      border-radius: 2px;
    }
  }
  .task-table {
    width: 100%;
    flex: 1;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-top:20px;
    padding: 20px 30px 0px 30px;
    overflow: hidden;
    .task-history {
      width: 100%;
    }
  }
}
</style>
